import React from 'react';
import ReactGA from 'react-ga';
import multibancoLogo from '../assets/multibanco_img_big.png';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import queryString from 'query-string';

class mbWayPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entity: '',
            reference: '',
            amount: '',
            donor: ''
        };

        this.goHome = this.goHome.bind(this);
    }

    goHome() {
        window.location = `/?donorName=${this.state.donor}&itsOwner=true`;
    }

    componentDidMount() {
        ReactGA.set({
            page: window.location.pathname + window.location.search
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        let { entity, reference, amount, donor } = queryString.parse(this.props.location.search);

        this.setState({ entity, reference, amount, donor });
    }

    render() {
        return (
            <div className="gf-donation-window multibanco-page">
                <div className="mbway-page-logo">
                    <img src={multibancoLogo}></img>
                </div>
                <div className="mbway-page-text">
                    Confirme a sua doação utilizando os seguintes dados num terminal Multibanco ou no seu Home Banking.
                </div>
                <div className="mbway-page-text">
                    Após confirmar o seu donativo, o seu nome ficará gravado e visível para todos no coração.
                </div>

                <div className="mb-data">
                    <table>
                        <tr>
                            <td className="mb-titles">Entidade:</td>
                            <td>{this.state.entity}</td>
                        </tr>
                        <tr>
                            <td className="mb-titles">Referêcia:</td>
                            <td>{this.state.reference}</td>
                        </tr>
                        <tr>
                            <td className="mb-titles">Valor:</td>
                            <td>{this.state.amount}</td>
                        </tr>
                    </table>
                </div>
                <div class="gf-submit-line">
                    <div class="gf-submit-button-container">
                        <button onClick={this.goHome} class="gf-submit-button primary-bg-color">
                            <span>Concluir</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(mbWayPage);

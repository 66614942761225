import React from 'react';
import ReactGA from 'react-ga';
import mbwayLogo from '../assets/mbway-logo-big.png';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

class mbWayPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            continueEnable: false
        };

        this.goThanksPage = this.goThanksPage.bind(this);
    }

    goThanksPage() {
        window.location = `/?donorName=${this.state.donor}&itsOwner=true`;
    }

    componentDidMount() {
        ReactGA.set({
            page: window.location.pathname + window.location.search
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        const { amount, donor } = queryString.parse(this.props.location.search);

        this.setState({ amount, donor });
    }

    render() {
        return (
            <div className="gf-donation-window mbway-page">
                <div className="iban-big-logo-container">
                    <div className="iban-big-logo">{this.props.t('Transferência Bancária')}</div>
                </div>
                <div className="iban-page-text">
                    {this.props.t('Utilize os seguintes dados IBAN para realizar a sua tranferência.')}
                </div>
                <div className="iban-data">
                    <div className="iban-data-item iban-page-bank">Centro Social Sagrados Corações / Casa Damião</div>
                    <div className="iban-data-item iban-page-bank">
                        {this.props.t('Banco')}: Caixa Geral de Depósitos
                    </div>
                    <div className="iban-data-item iban-page-swift">BIC SWIFT: CGDIPTPL</div>
                    <div className="iban-label">IBAN:</div>
                    <div className="iban-data-item iban-page-iban">PT50003502450000000553037</div>
                    <div>
                        {this.props.t('Valor')}: {this.state.amount} €
                    </div>
                </div>

                <div class="gf-submit-line">
                    <div class="gf-submit-button-container">
                        <button onClick={this.goThanksPage} class="gf-submit-button primary-bg-color">
                            <span>{this.props.t('Continuar')}</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(mbWayPage));

import React from 'react';

export default ({
    setState,
    formSubmit,
    loading,
    formDonationAmount,
    formDonationEmail,
    formDonationNotes,
    formDonationDonor,
    error
}) => (
    <div>
        <form className="admin-form-container" onSubmit={formSubmit}>
            <div>
                <div>Valor:</div>
                <input
                    disabled={loading}
                    value={formDonationAmount}
                    onChange={ev => {
                        setState({ formDonationAmount: ev.target.value });
                    }}
                    type="number"
                    required
                />
            </div>

            <div>
                <div>Nome no Coração: (opcional)</div>
                <input
                    disabled={loading}
                    value={formDonationDonor}
                    onChange={ev => {
                        setState({ formDonationDonor: ev.target.value });
                    }}
                />
            </div>

            <div>
                <div>Descrição: (opcional) </div>
                <input
                    disabled={loading}
                    value={formDonationNotes}
                    onChange={ev => {
                        setState({ formDonationNotes: ev.target.value });
                    }}
                />
            </div>

            <div>
                <div>Email: (opcional) </div>
                <input
                    disabled={loading}
                    value={formDonationEmail}
                    onChange={ev => {
                        setState({ formDonationEmail: ev.target.value });
                    }}
                    type="email"
                />
            </div>

            <div className="admin-error">{error}</div>
            <div>
                <button type="submit">Gravar Nome</button>
            </div>
        </form>
    </div>
);

import React from 'react';
import ReactGA from 'react-ga';
import mbwayLogo from '../assets/mbway-logo-big.png';
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import queryString from 'query-string';

class mbWayPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            continueEnable: false
        };

        this.goThanksPage = this.goThanksPage.bind(this);
    }

    goThanksPage() {
        window.location = `/?donorName=${this.state.donor}&itsOwner=true`;
    }

    componentDidMount() {
        ReactGA.set({
            page: window.location.pathname + window.location.search
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        const { phone, donor } = queryString.parse(this.props.location.search);

        this.setState({ phone, donor });

        setTimeout(() => {
            this.setState({ continueEnable: true });
        }, 16000);
    }

    render() {
        return (
            <div className="gf-donation-window mbway-page">
                <div className="mbway-page-logo">
                    <img src={mbwayLogo}></img>
                </div>
                <div className="mbway-page-text">Confirme a sua doação na aplicação do MB Way do seu telemóvel.</div>
                <div className="mbway-page-phone">Telemóvel: {this.state.phone}</div>

                <div class="gf-submit-line">
                    <div class="gf-submit-button-container">
                        <button
                            disabled={!this.state.continueEnable}
                            onClick={this.goThanksPage}
                            class="gf-submit-button primary-bg-color"
                        >
                            <span>Continuar</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(mbWayPage);

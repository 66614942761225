import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import btnLine from '../assets/button-line.svg';
import btnHeart from '../assets/heart-icon.svg';
import { withTranslation } from 'react-i18next';

function donateButton({ help, t }) {
    help = help || t('Faça bater corações');
    return (
        <div className="donation-button-all">
            <Link className="donation-button-container" to="/checkout">
                <div className="donate-btn">
                    {t('Fazer doação')}
                    <img className="button-line" src={btnLine} />
                    <img className="button-heart" src={btnHeart} />
                </div>
            </Link>
            <div className="donate-help-text">{help}</div>
        </div>
    );
}

export default withTranslation()(donateButton);

import React from 'react';
import heartImage from '../assets/heart-hug.png';
import { withTranslation } from 'react-i18next';

function hearts({ donorName, t, i18n }) {
    return (
        <React.Fragment>
            <div className="heart-container">
                <div className="thanks-container">
                    <div className="thanks">{t('Obrigado')}</div>
                    <div className="thanks-name">{donorName}</div>
                </div>
                <img className="heart" src={heartImage}></img>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(hearts);

import React from 'react';
import { withTranslation } from 'react-i18next';

function companies({ t, sponsors }) {
    return (
        <div className="companies-container">
            <div className="content-size">
                <div className="companies-title">{t('Empresas que fazem bater um coração')}</div>
                <div className="companies-logos">
                    {sponsors.map(sponsor => {
                        return (
                            <div key={sponsor.logoUrl} className="company-logo">
                                <a href={sponsor.url} target="_blank">
                                    <img src={sponsor.logoUrl}></img>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(companies);

import React from 'react';
import { withTranslation } from 'react-i18next';

function showPhoto({ url, name, text, t }) {
    return (
        <React.Fragment>
            <div className="child-info-container">
                <div className="photo-container">
                    <img className="child-photo" src={url}></img>
                </div>
                <div className="child-question">{t('Quem sou?')}</div>
                <div className="child-name">{name}</div>
                <div className="child-info">{text}</div>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(showPhoto);

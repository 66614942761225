import constants from './constants';

var formatNumber = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
});

function getDonations() {
    return fetch(`${constants.apiEndpoint}/get-donations.php`)
        .then(response => response.json())
        .then(response => {
            let payedNames = 0;
            let noPaymentNames = 0;
            const donors = response.donations
                .map(donor => {
                    if (donor.payed == 1) {
                        payedNames++;
                    } else {
                        noPaymentNames++;
                    }

                    const amount = donor.amount || '';

                    return {
                        id: donor.id,
                        donor: donor.donor_name,
                        payed: donor.payed == 1,
                        anonym: donor.anonym == 1,
                        method: donor.payment_method,
                        createdAt: donor.created_at,
                        amount: formatNumber.format(Math.round(amount / 100)),
                        email: donor.email
                    };
                })
                .filter(donor => donor.payed && !donor.anonym);

            const donationStats = response.donations.reduce(
                function(acc, donation) {
                    if (donation.payed == 1) {
                        acc.totalAmount = acc.totalAmount + Number(donation.total_amount);
                        acc.numberOfDonors++;

                        if (!acc.amounts[donation.total_amount]) {
                            acc.amounts[donation.total_amount] = 0;
                        }

                        acc.amounts[donation.total_amount]++;
                        const method = donation.payment_method;

                        if (method == 'card') {
                            acc.numberCardPayments++;
                        } else if (method == 'mbway') {
                            acc.numberMBwayPayments++;
                        } else if (method == 'multibanco') {
                            acc.numberMBPayments++;
                        } else if (method == 'manual') {
                            acc.numberManualPayments++;
                        } else {
                            acc.noMethodPayments++;
                        }
                    }

                    return acc;
                },
                {
                    totalAmount: 0,
                    numberOfDonors: 0,
                    numberCardPayments: 0,
                    numberMBwayPayments: 0,
                    numberManualPayments: 0,
                    numberMBPayments: 0,
                    noMethodPayments: 0,
                    amounts: {}
                }
            );

            const donations = response.donations.map(donation => {
                return {
                    id: donation.id,
                    donor: donation.donor_name,
                    amount: formatNumber.format(Math.round(donation.total_amount / 100)),
                    paymentMethod: donation.payment_method,
                    email: donation.email,
                    updatedAt: donation.updated_at,
                    notes: donation.notes,
                    createdAt: donation.created_at,
                    anonym: donation.anonym == 1,
                    payed: donation.payed == 1
                };
            });

            donationStats.totalAmount = Math.round(donationStats.totalAmount / 100);
            donationStats.totalAmount = formatNumber.format(parseInt(donationStats.totalAmount));

            donationStats.payedNames = payedNames;
            donationStats.noPaymentNames = noPaymentNames;

            const messages = response.messages.map(message => {
                return {
                    message: message.message,
                    nameId: message.name_id,
                    name: message.name,
                    url: message.url,
                    logoUrl: message.logo_url,
                    id: message.id
                };
            });

            //sponsers
            let sponsors = (response.sponsors && response.sponsors[0] && response.sponsors[0].sponsors) || '[]';
            sponsors = JSON.parse(sponsors);
            sponsors = sponsors.sort((a, b) => {
                return a.displayOrder - b.displayOrder;
            });

            return {
                donors,
                donationStats,
                messages,
                donations,
                sponsors
            };
        });
}

export { getDonations };

import React from 'react';
import ReactDOM from 'react-dom';
import HomePage from './pages/home-page';
import Checkout from './pages/checkout';
import { Elements, StripeProvider } from 'react-stripe-elements';

import MbWayPage from './pages/mbway-page.js';
import IbanPage from './pages/iban-page.js';
import MbPage from './pages/multibanco-page.js';
import AdminPage from './pages/admin-page.js';
import ScrollToTop from './components/scroll-to-top';
import Footer from './components/footer';
import ReactGA from 'react-ga';
import constants from './constants';

import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import './i18n';

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('UA-58151786-7');
}

import './css/normalize.css';
import './css/elements.css';
import './css/admin.css';
import './css/app.css';
import './css/stripe.css';

class AppComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <StripeProvider apiKey={constants.stripeApiKey}>
                <Router>
                    <div className="top-line"></div>
                    <div className="top-bar">
                        <div className="logo-container">
                            <a href="/">
                                <img src="https://casadamiao.pt/wp-content/uploads/2018/08/cropped-logo-casa-damic3a3o-2.jpg"></img>
                            </a>
                        </div>
                        <div>
                            <a className="top-bar-link" href="https://casadamiao.pt">
                                CasaDamião.pt
                            </a>
                            <a className="top-bar-link" href="https://casadamiao.pt/contatos/">
                                Contactos
                            </a>
                        </div>
                    </div>
                    <ScrollToTop />
                    <Switch>
                        <Route path="/mbway">
                            <MbWayPage></MbWayPage>
                        </Route>
                        <Route path="/iban">
                            <IbanPage></IbanPage>
                        </Route>

                        <Route path="/multibanco">
                            <MbPage></MbPage>
                        </Route>
                        <Route path="/checkout">
                            <Elements>
                                <Checkout></Checkout>
                            </Elements>
                        </Route>
                        <Route path="/admin">
                            <AdminPage></AdminPage>
                        </Route>
                        <Route path="/">
                            <HomePage />
                        </Route>
                    </Switch>
                    <Footer></Footer>
                </Router>
            </StripeProvider>
        );
    }
}

let App = document.getElementById('react-app');

ReactDOM.render(<AppComponent />, App);

import React from 'react';
import CompanyMessage from './company-message';

function companiesMessages({ messages, tryDeleteId, tryDeleteCompany, deleteCompany, closeTryDelete, editCompany }) {
    return (
        <div>
            {messages.map((message, i) => {
                return (
                    <div className="admin-messages" key={i}>
                        <button className="btn-operations" onClick={() => editCompany(message.id)}>
                            Editar
                        </button>
                        <button
                            onClick={() => {
                                tryDeleteCompany(message.id);
                            }}
                            className="btn-operations admin-delete-button"
                        >
                            Apagar
                        </button>
                        {tryDeleteId === message.id && (
                            <div>
                                Tem a certeza que quer apagar?
                                <div>
                                    <button onClick={() => deleteCompany(message.id)} className="btn-operations">
                                        Sim apagar
                                    </button>
                                    <button onClick={closeTryDelete}>Fechar</button>
                                </div>
                            </div>
                        )}
                        <div>
                            <h3>{message.name}</h3>
                        </div>
                        <div>
                            <a href={`https://doar.casadamiao.pt/?b=${message.nameId}`} target="_balnk">
                                https://doar.casadamiao.pt/?b={message.nameId}
                            </a>
                        </div>
                        <CompanyMessage
                            message={message.message}
                            url={message.url}
                            logoUrl={message.logoUrl}
                        ></CompanyMessage>
                    </div>
                );
            })}
        </div>
    );
}

export default companiesMessages;

import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';

import visaCardIcon from '../assets/visa-card.png';
import mastercardCardIcon from '../assets/mastercard-card.png';
import maestroCardIcon from '../assets/maestro-card.png';
import multibancoIcon from '../assets/multibanco.png';
import mbwayIcon from '../assets/mbway.png';
import Hearts from '../components/hearts.js';
import { withTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import constants from '../constants';
import ReactGA from 'react-ga';
import amountIcon from '../assets/coins.png';
import selectedIcon from '../assets/amounts/selected_icon.png';

const formConstants = {
    NONE_PAYMENT: 'NONE_PAYMENT',
    CARD_PAYMENT: 'CARD_PAYMENT',
    MULTIBANCO_PAYMENT: 'MULTIBANCO_PAYMENT',
    MB_WAY_PAYMENT: 'MB_WAY_PAYMENT',
    IBAN: 'IBAN'
};

function AmountSelector({ amount, activePaymentAmount, customAmountEnable, onClick }) {
    return (
        <div
            onClick={onClick}
            className={
                'gf-donation-box ' +
                (activePaymentAmount === amount && !customAmountEnable ? 'gf-donation-box-active' : '')
            }
        >
            <div className="selected-amount-icon">
                <img src={selectedIcon}></img>
            </div>
            <img className="amount-icon" src={amountIcon}></img>
            <div className="amount-value">
                {amount}
                <span className="euro-amount-icon">€</span>
            </div>
        </div>
    );
}
class CheckoutForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activePaymentMethod: formConstants.NONE_PAYMENT,
            montlyPaymentActive: false,
            activePaymentAmount: 1,
            customAmountEnable: false,
            anonymous: false,
            errorMessage: '',
            donorName: '',
            donorMail: '',
            mbwayPhone: '',
            loading: false
        };

        this.formIsValid = this.formIsValid.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.clearErrorMessage = this.clearErrorMessage.bind(this);
        this.cardPayment = this.cardPayment.bind(this);
        this.multibancoPayment = this.multibancoPayment.bind(this);
        this.mbWayPayment = this.mbWayPayment.bind(this);

        this.customAmountInputRef = React.createRef();
    }

    clearErrorMessage() {
        this.setState({ errorMessage: '' });
    }

    formIsValid() {
        if (this.state.activePaymentAmount === '') {
            return false;
        }

        return true;
    }

    componentDidMount() {
        ReactGA.set({
            page: window.location.pathname + window.location.search
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    cardPayment() {
        let params = `?paymentType=${this.state.activePaymentMethod}`;
        params += `&amount=${this.state.activePaymentAmount}`;
        params += `&donorName=${this.state.donorName}`;
        params += `&email=${this.state.donorMail}`;
        this.setState({ loading: true });
        return fetch(`${constants.apiEndpoint}/create-donation.php${params}`)
            .then(response => response.json())
            .then(response => {
                const billing_details = {
                    email: this.state.donorMail
                };

                if (this.state.donorName) {
                    billing_details.name = this.state.donorName;
                }

                return this.props.stripe.confirmCardPayment(response.client_secret, {
                    payment_method: {
                        card: this.props.elements.getElement('card'),
                        billing_details
                    }
                });
            })
            .then(response => {
                if (response.error) {
                    this.setState({ errorMessage: response.error.message });
                }

                if (response.paymentIntent && response.paymentIntent.status === 'succeeded') {
                    window.location = `/?donorName=${this.state.donorName}&itsOwner=true`;
                }
            })
            .catch(() => {
                this.setState({ errorMessage: 'Ocorreu um erro. Verifique os dados e tente novamente.' });
            })
            .then(() => {
                this.setState({ loading: false });
            });
    }

    multibancoPayment() {
        const owner = {
            email: this.state.donorMail
        };

        if (this.state.donorName) {
            owner.name = this.state.donorName;
        }

        this.setState({ loading: true });

        this.props.stripe
            .createSource({
                type: 'multibanco',
                amount: Math.round(this.state.activePaymentAmount * 100),
                currency: 'eur',
                owner,
                redirect: {
                    return_url: 'https://doar.casadamiao.pt/'
                }
            })
            .then(result => {
                if (
                    result.source &&
                    result.source.amount &&
                    result.source.multibanco &&
                    result.source.multibanco.reference
                ) {
                    const dataMb = result.source;
                    var formatNumber = new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 0
                    });

                    const amount = result.source.amount;
                    const amountEuro = formatNumber.format(Math.round(result.source.amount / 100));

                    fetch(
                        `${constants.apiEndpoint}/create_donation_mb.php?reference=${dataMb.multibanco.reference}&email=${dataMb.owner.email}&entity=${dataMb.multibanco.entity}&amount=${amount}&paymentId=${dataMb.id}&donor=${this.state.donorName}`
                    ).then(() => {
                        window.location = `/multibanco?entity=${dataMb.multibanco.entity}&reference=${dataMb.multibanco.reference}&amount=${amountEuro}&donor=${this.state.donorName}`;
                    });
                }
            })
            .catch(() => {
                this.setState({ errorMessage: 'Ocorreu um erro. Verifique os dados e tente novamente.' });
            })
            .then(() => {
                this.setState({ loading: false });
            });
    }

    mbWayPayment() {
        this.setState({ loading: true });
        fetch(
            `${constants.apiEndpoint}/create-donation-mbway.php?amount=${this.state.activePaymentAmount}&phone=${this.state.mbwayPhone}&email=${this.state.donorMail}&name=${this.state.donorName}`
        )
            .then(response => response.json())
            .then(response => {
                if (response.status == 'error') {
                    this.setState({ errorMessage: response.message[0] });
                    return;
                }

                window.location = `/mbway?phone=${this.state.mbwayPhone}&donor=${this.state.donorName}`;
            })
            .catch(() => {
                this.setState({ errorMessage: 'Ocorreu um erro. Verifique os dados e tente novamente.' });
            })
            .then(() => {
                this.setState({ loading: false });
            });
    }

    ibanPayment() {
        this.setState({ loading: true });
        return fetch(
            `${constants.apiEndpoint}/create-donation_iban.php?amount=${this.state.activePaymentAmount}&email=${this.state.donorMail}&name=${this.state.donorName}`
        )
            .then(response => response.json())
            .then(response => {
                if (response.status == 'error') {
                    this.setState({ errorMessage: response.message[0] });
                    return;
                }

                window.location = `/iban?amount=${this.state.activePaymentAmount}&donor=${this.state.donorName}`;
            })
            .catch(() => {
                this.setState({ errorMessage: 'Ocorreu um erro. Verifique os dados e tente novamente.' });
            })
            .then(() => {
                this.setState({ loading: false });
            });
    }

    formSubmit(ev) {
        ev.preventDefault();

        this.clearErrorMessage();

        if (this.state.activePaymentMethod == formConstants.CARD_PAYMENT) {
            return this.cardPayment();
        }

        if (this.state.activePaymentMethod == formConstants.MULTIBANCO_PAYMENT) {
            return this.multibancoPayment();
        }

        if (this.state.activePaymentMethod == formConstants.MB_WAY_PAYMENT) {
            return this.mbWayPayment();
        }

        if (this.state.activePaymentMethod == formConstants.IBAN) {
            return this.ibanPayment();
        }

        return this.setState({ errorMessage: 'Selecione um método de pagamento.' });
    }

    render() {
        return (
            <main className="gf-donation-window">
                {this.state.loading && <div className="loading-page"></div>}
                <div id="checkout">
                    <div id="payment-request"></div>
                    <form id="payment-form" onSubmit={this.formSubmit}>
                        <section className="group-section">
                            <h2 className="gf-box-heading">
                                <span className="gf-box-heading-number secondary-bg-color">1</span>
                                {this.props.t('Adicionar nome ao coração')}
                            </h2>

                            {!this.state.anonymous && (
                                <div className="gf-input-line">
                                    <div className="gf-input-box">
                                        <input
                                            autoFocus
                                            className="gf-input-text"
                                            type="text"
                                            placeholder=""
                                            value={this.state.donorName}
                                            onChange={ev => {
                                                this.setState({ donorName: ev.target.value });
                                            }}
                                            disabled={this.state.loading}
                                            required
                                        />
                                        <label className="gf-input-label" htmlFor="donation_first_name">
                                            {this.props.t('Nome no coração')}
                                        </label>
                                    </div>
                                </div>
                            )}
                            <div
                                className={'gf-input-line help-line' + (this.state.anonymous ? ' monthly-active ' : '')}
                                onClick={() => {
                                    this.setState({ anonymous: !this.state.anonymous, donorName: '' });
                                }}
                            >
                                <input
                                    className="gf-input-checkbox"
                                    type="checkbox"
                                    value="1"
                                    checked={this.state.anonymous}
                                    readOnly
                                    disabled={this.state.loading}
                                />
                                <span className="gf-input-label monthly-label" htmlFor="gf-input-monthly">
                                    {this.props.t('Quero ser anónimo')}
                                </span>
                            </div>

                            {!this.state.anonymous && (
                                <div className="heart-checkout">
                                    <Hearts donorName={this.state.donorName}></Hearts>
                                </div>
                            )}

                            <div className="gf-input-line">
                                <div className="gf-input-box">
                                    <input
                                        className="gf-input-text"
                                        id="gf-input-email"
                                        required="required"
                                        type="email"
                                        aria-required="true"
                                        value={this.state.donorMail}
                                        onChange={ev => {
                                            this.setState({ donorMail: ev.target.value });
                                        }}
                                        disabled={this.state.loading}
                                    />
                                    <label className="gf-input-label" htmlFor="gf-input-email">
                                        {this.props.t('Email')}
                                    </label>
                                </div>
                            </div>
                        </section>
                        <section className="group-section">
                            <h2 className="gf-box-heading">
                                <span className="gf-box-heading-number secondary-bg-color">2</span>
                                {this.props.t('Quanto quer doar?')}
                            </h2>
                            <div className="head-line">
                                {this.props.t('Ao doar está a ajudar crianças com problemas cardíacos.')}
                            </div>

                            <div className="gf-content">
                                <div className="gf-donation-boxes">
                                    <AmountSelector
                                        activePaymentAmount={this.state.activePaymentAmount}
                                        customAmountEnable={this.state.customAmountEnable}
                                        onClick={() => {
                                            this.setState({
                                                customAmountEnable: false,
                                                activePaymentAmount: 1
                                            });
                                            ReactGA.event({
                                                category: 'select amount',
                                                action: 'select first'
                                            });
                                        }}
                                        amount={1}
                                    ></AmountSelector>
                                    <AmountSelector
                                        activePaymentAmount={this.state.activePaymentAmount}
                                        customAmountEnable={this.state.customAmountEnable}
                                        onClick={() => {
                                            this.setState({
                                                customAmountEnable: false,
                                                activePaymentAmount: 2
                                            });
                                            ReactGA.event({
                                                category: 'select amount',
                                                action: 'select seccond'
                                            });
                                        }}
                                        amount={2}
                                    ></AmountSelector>
                                    <AmountSelector
                                        activePaymentAmount={this.state.activePaymentAmount}
                                        customAmountEnable={this.state.customAmountEnable}
                                        onClick={() => {
                                            this.setState({
                                                customAmountEnable: false,
                                                activePaymentAmount: 5
                                            });

                                            ReactGA.event({
                                                category: 'select amount',
                                                action: 'select third'
                                            });
                                        }}
                                        amount={5}
                                    ></AmountSelector>
                                    <div
                                        onClick={() => {
                                            setTimeout(() => {
                                                this.customAmountInputRef.current.focus();
                                            }, 0);
                                            if (!this.state.customAmountEnable) {
                                                this.setState({ customAmountEnable: true, activePaymentAmount: '' });
                                            }
                                            ReactGA.event({
                                                category: 'select amount',
                                                action: 'select custom'
                                            });
                                        }}
                                        className={
                                            'gf-donation-box gf-donation-custom' +
                                            (this.state.customAmountEnable
                                                ? ' gf-donation-box-active gf-donation-custom-active'
                                                : '')
                                        }
                                    >
                                        <div className="selected-amount-icon">
                                            <img src={selectedIcon}></img>
                                        </div>
                                        <img className="amount-icon" src={amountIcon}></img>
                                        <div className="another-amount"> {this.props.t('Outro valor')}</div>
                                        {this.state.customAmountEnable && (
                                            <React.Fragment>
                                                <input
                                                    className="donation-custom-amount"
                                                    ref={this.customAmountInputRef}
                                                    type="number"
                                                    min={1}
                                                    step=".01"
                                                    onChange={e => {
                                                        const activePaymentAmount = e.target.value;
                                                        this.setState({ activePaymentAmount });
                                                    }}
                                                    value={this.state.activePaymentAmount}
                                                    required
                                                    disabled={this.state.loading}
                                                />
                                                <span className="euro-custom">€</span>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="group-section">
                            <h2 className="gf-box-heading">
                                <span className="gf-box-heading-number secondary-bg-color">3</span>
                                {this.props.t('Pagamento')}
                            </h2>
                            <nav className="payment-methods">
                                <div className="payment-method">
                                    <div
                                        onClick={() => {
                                            this.setState({
                                                errorMessage: '',
                                                activePaymentMethod: formConstants.CARD_PAYMENT
                                            });
                                        }}
                                        className="payment-name-container"
                                    >
                                        <div className="payment-flex">
                                            <input
                                                className="gf-input-checkbox"
                                                type="radio"
                                                checked={this.state.activePaymentMethod === formConstants.CARD_PAYMENT}
                                                readOnly
                                                disabled={this.state.loading}
                                            />
                                            <div className="payment-method-name">
                                                {this.props.t('Cartão de crédito/débito')}
                                                <div className="method-icons">
                                                    <img src={visaCardIcon} />
                                                    <img src={mastercardCardIcon} />
                                                    <img src={maestroCardIcon} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.activePaymentMethod === formConstants.CARD_PAYMENT && (
                                        <div className="payment-method-container">
                                            <div className="gf-input-line">
                                                <div className="gf-input-box ">
                                                    <div className="gf-input-text">
                                                        <CardElement disabled={this.state.loading} required={true} />
                                                    </div>
                                                    <label className="gf-input-label"> {this.props.t('Cartão')}</label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="payment-method">
                                    <div
                                        className="payment-name-container"
                                        onClick={() => {
                                            if (this.state.montlyPaymentActive) {
                                                return;
                                            }
                                            this.setState({
                                                errorMessage: '',
                                                activePaymentMethod: formConstants.MULTIBANCO_PAYMENT
                                            });
                                        }}
                                    >
                                        <div className="payment-flex">
                                            <input
                                                className="gf-input-checkbox"
                                                type="radio"
                                                checked={
                                                    this.state.activePaymentMethod === formConstants.MULTIBANCO_PAYMENT
                                                }
                                                readOnly
                                                disabled={this.state.loading}
                                            />
                                            <div className="payment-method-name">
                                                {this.props.t('Multibanco')}
                                                <div className="method-icons">
                                                    <img src={multibancoIcon} />
                                                </div>
                                                {this.state.montlyPaymentActive && (
                                                    <div className="payment-note">
                                                        {this.props.t('Não disponível para doações mensais.')}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="payment-method">
                                    <div
                                        className="payment-name-container"
                                        onClick={() => {
                                            if (this.state.montlyPaymentActive) {
                                                return;
                                            }
                                            this.setState({
                                                errorMessage: '',
                                                activePaymentMethod: formConstants.MB_WAY_PAYMENT
                                            });
                                        }}
                                    >
                                        <div className="payment-flex">
                                            <input
                                                className="gf-input-checkbox"
                                                type="radio"
                                                checked={
                                                    this.state.activePaymentMethod === formConstants.MB_WAY_PAYMENT
                                                }
                                                readOnly
                                                disabled={this.state.loading}
                                            />

                                            <div className="payment-method-name">
                                                MB Way
                                                <div className="method-icons">
                                                    <img src={mbwayIcon} />
                                                </div>
                                                {this.state.montlyPaymentActive && (
                                                    <div className="payment-note">
                                                        {this.props.t('Não disponível para doações mensais.')}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.activePaymentMethod === formConstants.MB_WAY_PAYMENT && (
                                        <div className="payment-method-container">
                                            <div className="gf-input-line">
                                                <div className="gf-input-box">
                                                    <input
                                                        className="gf-input-text"
                                                        type="number"
                                                        value={this.state.mbwayPhone}
                                                        onChange={ev => {
                                                            this.setState({ mbwayPhone: ev.target.value });
                                                        }}
                                                        required
                                                        disabled={this.state.loading}
                                                    />
                                                    <label className="gf-input-label" htmlFor="gf-input-email">
                                                        {this.props.t('Nº de telemóvel associado à conta MB WAY')}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="payment-method">
                                    <div
                                        className="payment-name-container"
                                        onClick={() => {
                                            if (this.state.montlyPaymentActive) {
                                                return;
                                            }
                                            this.setState({
                                                errorMessage: '',
                                                activePaymentMethod: formConstants.IBAN
                                            });
                                        }}
                                    >
                                        <div className="payment-flex">
                                            <input
                                                className="gf-input-checkbox"
                                                type="radio"
                                                checked={this.state.activePaymentMethod === formConstants.IBAN}
                                                readOnly
                                                disabled={this.state.loading}
                                            />
                                            <div className="payment-method-name">
                                                {this.props.t('Transferência Bancária')}
                                                <span className="iban-icon">IBAN</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </section>
                        <div className="gf-submit-line">
                            <div className="error-line">{this.state.errorMessage}</div>

                            <div className="gf-submit-button-container">
                                <button disabled={this.state.loading} className="gf-submit-button primary-bg-color">
                                    {this.state.loading && <span> {this.props.t('Efectuando pagamento')}</span>}
                                    {!this.state.loading && this.state.activePaymentAmount > 0 && (
                                        <span>
                                            {this.props.t('Adicionar nome e doar')} {this.state.activePaymentAmount} €
                                        </span>
                                    )}
                                    {!this.state.loading && this.state.activePaymentAmount == 0 && (
                                        <span>{this.props.t('Adicionar nome')}</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        );
    }
}

export default withTranslation()(injectStripe(CheckoutForm));

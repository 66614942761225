import React from 'react';
import Stats from './stats';
import ReactGA from 'react-ga';
import angelaImg from '../assets/photos/angela.jpg';
import ussumaneImg from '../assets/photos/ussumane.png';
import casaDamiaoLogo from '../assets/logo-damiao.png';
import showPhoto from '../components/photo';
import DonateButton from '../components/donate-button';
import { withTranslation } from 'react-i18next';

function information({ t }) {
    return (
        <React.Fragment>
            <div className="big-area">
                <div className="content-side-by-side">
                    <div className="two-content photo-1">
                        {showPhoto({
                            url: ussumaneImg,
                            name: t('O Ussumane'),
                            text: `"${t(
                                'Cheguei à Casa Damião com 7 anos e sonho um dia conhecer o Cristiano Ronaldo.'
                            )}"`
                        })}
                    </div>
                    <div className="two-content">
                        <h3>{t('Porquê ajudar')}</h3>
                        <p className="bottom-space">
                            {t(
                                'Porque tal como o Ussumane, outras crianças com problemas cardíacos procuram uma oportunidade para melhorar a sua condição de vida.'
                            )}
                        </p>
                        <h3>{t('Quem são as crianças')}</h3>
                        <p>
                            {t(
                                'São crianças dos Países Africanos de Língua Oficial Portuguesa (PALOP), que vivem em extrema pobreza, isoladas, em situação de risco de vida e sem tratamento no seu país de origem.'
                            )}
                        </p>
                        <div className="align-donation">
                            <DonateButton />
                        </div>
                    </div>
                </div>
            </div>
            <div className="big-area big-area-color">
                <div className="text-center-space">
                    <div className="text-center content-size">
                        <h3>{t('Quem somos?')}</h3>
                        <div className="logo-casa-damiao">
                            <img src={casaDamiaoLogo}></img>
                        </div>
                    </div>

                    <div className="text-center content-size content-size-small">
                        {t(
                            'A Casa Damião é uma resposta social inovadora no acolhimento temporário de crianças. Asseguramos assistência nos cuidados de saúde e garantimos um acolhimento confortável, seguro e digno num ambiente tanto quanto possível familiar.'
                        )}
                    </div>
                </div>
            </div>

            <div className="big-area">
                <div className=" content-side-by-side">
                    <div className="two-content photo-1">
                        {showPhoto({
                            url: angelaImg,
                            name: t('A Angela'),
                            text: `"${t('Cheguei à Casa Damião com 8 anos e quero ser enfermeira.')}"`
                        })}
                    </div>
                    <div className="two-content">
                        <h3>{t('O que fazemos?')}</h3>
                        <p>
                            {t(
                                'Garantimos o acolhimento, acompanhamento e tratamento dos cuidados de saúde da criança em Portugal em estreita colaboração com o hospital de referência.'
                            )}
                        </p>
                        <p className="bottom-space">
                            {t(
                                'Após alta médica definitiva, asseguramos o regresso da criança ao seu país de origem, garantindo a continuidade dos cuidados de saúde em colaboração com entidades locais.'
                            )}
                        </p>
                        <div className="align-donation">
                            <DonateButton />
                        </div>
                    </div>
                </div>
            </div>

            <Stats></Stats>

            <div className="text-center-space">
                <div className="text-center content-size ">
                    <h3>{t('Porque é importante o seu donativo')}</h3>
                </div>
                <div className="text-center content-size content-size-small">
                    {t(
                        'Ambicionamos criar maior robustez financeira de forma a acolher e salvar mais crianças. As nossas instalações estão preparadas para dar resposta a mais pedidos e estamos motivados para o fazer, o seu contributo ajudar-nos-á a atingir estas metas.'
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(information);

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import es from './i18n/es.json';
import pt from './i18n/pt.json';
import en from './i18n/en.json';

const resources = {
    es: {
        translation: es
    },
    pt: {
        translation: pt
    },
    en: {
        translation: en
    }
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;

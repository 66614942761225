import React from 'react';
import Information from '../components/information';
import Hearts from '../components/hearts.js';
import CompanyMessage from '../components/company-message';

import Companies from '../components/companies';
import ShareButtons from '../components/share-buttons';
import DonateButton from '../components/donate-button';
import NamesSlider from '../components/names-slider';
import shuffle from 'shuffle-array';
import queryString from 'query-string';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { getDonations } from '../http-helper';
import copy from 'copy-to-clipboard';
import ReactGA from 'react-ga';
import * as Scroll from 'react-scroll';

import { withRouter } from 'react-router';

const LinkTo = Scroll.Link;
const ElementTo = Scroll.Element;

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shareableUrl: '',
            itsOwner: false,
            showShareTooltip: false,
            sharedBy: '',
            sharedByMessage: '',
            donors: [],
            donorIndex: 0,
            donationStats: { totalAmount: '', numberOfDonors: '' },
            donorSharingName: '',
            titleImage: '',
            sponsors: [],
            showAddName: true
        };

        this.startTimerForNames = this.startTimerForNames.bind(this);
        this.share = this.share.bind(this);
        this.changeDonorIndex = this.changeDonorIndex.bind(this);
        this.userChangeDonorPosition = this.userChangeDonorPosition.bind(this);

        this.intervalId = null;
    }

    share() {
        const url = this.state.shareableUrl;

        if (navigator.share) {
            ReactGA.event({
                category: 'share',
                action: 'native share'
            });

            return navigator.share({
                url
            });
        }

        copy(url);

        ReactGA.event({
            category: 'share',
            action: 'copy share'
        });

        this.setState({ showShareTooltip: true });

        setTimeout(() => {
            this.setState({ showShareTooltip: false });
        }, 3000);
    }

    changeDonorIndex(donorIndex) {
        if (!this.state.donors.length) {
            return;
        }

        if (donorIndex === this.state.donors.length - 1) {
            this.setState({ showAddName: true });
            ReactGA.event({
                category: 'range input',
                action: 'end position'
            });
        }

        const donor = this.state.donors[donorIndex];

        this.setState({ donorIndex: donorIndex, donorName: donor.donor });
    }

    componentDidMount() {
        ReactGA.set({
            page: window.location.pathname + window.location.search
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        const { donorName, itsOwner, b } = queryString.parse(this.props.location.search);

        this.setState({ donorSharingName: donorName });

        const sharedBy = b;
        const shareableUrl = window.location.href.replace('itsOwner', 'sharinglove');

        this.setState({ shareableUrl, itsOwner });

        getDonations().then(donations => {
            const sharedByMessage = donations.messages.find(message => message.nameId == sharedBy);
            const donors = shuffle(donations.donors);
            this.setState({
                donors: donors,
                donationStats: donations.donationStats,
                donorName: donorName ? donorName : donations.donors[donations.donors.length - 1].donor,
                sharedBy,
                sharedByMessage,
                sponsors: donations.sponsors
            });
        });

        if (itsOwner && donorName) {
            this.setState({ donorName: donorName });
            return;
        }

        // show name for ever
        if (donorName) {
            return;
        }

        this.startTimerForNames();
    }

    startTimerForNames() {
        this.intervalId = setInterval(() => {
            let donorIndex = this.state.donorIndex;
            donorIndex++;

            if (donorIndex >= this.state.donors.length) {
                return clearInterval(this.intervalId);
            }

            this.changeDonorIndex(donorIndex);
        }, 2000);
    }

    userChangeDonorPosition(indexArray) {
        const index = indexArray[0];
        clearInterval(this.intervalId);

        this.changeDonorIndex(index);

        ReactGA.event({
            category: 'range input',
            action: 'move range'
        });
    }

    render() {
        return (
            <div className="home-page">
                <div className="top-container">
                    <div className="page-header">
                        {this.state.itsOwner && (
                            <div>
                                <h1 className="page-title">
                                    <div>{this.props.t('Obrigado pelo seu contributo.')}</div>
                                    <div className="title-second">{this.props.t('Partilhe o seu coração.')}</div>
                                </h1>
                                <ShareButtons
                                    shareFn={this.share}
                                    shareableUrl={this.state.shareableUrl}
                                    showShareTooltip={this.state.showShareTooltip}
                                ></ShareButtons>
                                <div className="share-help share-help-strong">
                                    {this.props.t('Juntos fazemos bater corações')}
                                </div>
                            </div>
                        )}

                        {!this.state.itsOwner && (
                            <React.Fragment>
                                <div className="header-text">
                                    <h1 className="page-main-title">{this.props.t('Faça bater um coração')}</h1>
                                </div>
                            </React.Fragment>
                        )}
                        <div className="header-heart">
                            <Hearts
                                heartPercentage={this.state.heartPercentage}
                                donorName={this.state.donorName}
                            ></Hearts>
                        </div>
                    </div>
                    <div className="slider-names-container">
                        <div className="slider-names">
                            <NamesSlider
                                names={this.state.donors}
                                onChange={this.userChangeDonorPosition}
                                value={this.state.donorIndex}
                            ></NamesSlider>
                            {this.state.showAddName && (
                                <div className="add-your-name">
                                    <Link to="/checkout">
                                        <button className="add-your-name-btn">{this.props.t('Adicionar nome')}</button>
                                    </Link>
                                </div>
                            )}
                            <div
                                onClick={() => {
                                    this.userChangeDonorPosition([this.state.donors.length - 1]);
                                }}
                                className="end-range"
                            >
                                <div className="you-message">{this.props.t('E você?')}</div>
                            </div>
                        </div>
                    </div>

                    {!!this.state.sharedByMessage && (
                        <CompanyMessage
                            message={this.state.sharedByMessage.message}
                            url={this.state.sharedByMessage.url}
                            logoUrl={this.state.sharedByMessage.logoUrl}
                        ></CompanyMessage>
                    )}

                    <div className="donation-stats">
                        <div className="donation-stats-text">
                            <div>
                                {this.state.donationStats.numberOfDonors} {this.props.t('doações')},{' '}
                                {this.state.donationStats.totalAmount} {this.props.t('angariados. Obrigado!')}
                            </div>
                        </div>
                        <LinkTo smooth={true} to="know-more">
                            {this.props.t('Saber mais')}
                        </LinkTo>
                    </div>

                    {!this.state.itsOwner && (
                        <React.Fragment>
                            <ShareButtons
                                shareFn={this.share}
                                shareableUrl={this.state.shareableUrl}
                                showShareTooltip={this.state.showShareTooltip}
                            ></ShareButtons>
                            <div className="share-help">{this.props.t('Ao partilhar já está a ajudar')}</div>
                        </React.Fragment>
                    )}
                </div>

                <div className="big-area big-area-color second-title">
                    <div className="text-center ">
                        <h2>{this.props.t('Ajude-nos a salvar mais crianças em 2022.')}</h2>
                    </div>
                </div>
                <div name="know-more"></div>
                <Information></Information>
                <DonateButton></DonateButton>

                <Companies sponsors={this.state.sponsors}></Companies>
            </div>
        );
    }
}

export default withTranslation()(withRouter(HomePage));

import React from 'react';

export default ({
    setState,
    formSubmit,
    loading,
    newCompanyName,
    newCompanyNameId,
    newCompanyUrl,
    newCompanyMessage,
    newCompanyLogoUrl,
    error
}) => (
    <div>
        <form className="admin-form-container" onSubmit={formSubmit}>
            <div>
                <div>Nome:</div>
                <input
                    disabled={loading}
                    value={newCompanyName}
                    onChange={ev => {
                        setState({ newCompanyName: ev.target.value });
                    }}
                    required
                />
            </div>
            <div>
                <div>Id de partilha. ex: ctt</div>
                <input
                    disabled={loading}
                    value={newCompanyNameId}
                    onChange={ev => {
                        setState({ newCompanyNameId: ev.target.value });
                    }}
                    required
                />
            </div>
            <div>
                <div>Site da empresa. Começar por https://</div>
                <input
                    disabled={loading}
                    type="url"
                    value={newCompanyUrl}
                    onChange={ev => {
                        setState({ newCompanyUrl: ev.target.value });
                    }}
                    pattern="https://.*"
                    required
                />
            </div>

            <div>
                <div>Url da image. Começar por https://</div>
                <input
                    type="url"
                    disabled={loading}
                    value={newCompanyLogoUrl}
                    onChange={ev => {
                        setState({ newCompanyLogoUrl: ev.target.value });
                    }}
                    pattern="https://.*"
                    required
                />
            </div>

            <div>
                <div>Mensagem:</div>
                <input
                    disabled={loading}
                    value={newCompanyMessage}
                    onChange={ev => {
                        setState({ newCompanyMessage: ev.target.value });
                    }}
                    required
                />
            </div>

            <div>
                <div>
                    <h2>Como vai ficar:</h2>
                </div>
                <div className="company-share-message-container">
                    <div className="company-share-message">
                        <div className="company-share-quote">
                            <span className="aspas">"</span>
                            {newCompanyMessage}
                            <span className="aspas">"</span>
                        </div>
                        <a href={newCompanyUrl} target="_blank">
                            <div className="company-share-logo">
                                <img src={newCompanyLogoUrl}></img>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="admin-error">{error}</div>
            <div>
                <button type="submit">Gravar Empresa</button>
            </div>
        </form>
    </div>
);

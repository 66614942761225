import React from 'react';
import ReactGA from 'react-ga';

import facebookBtn from '../assets/facebook-btn.png';
import twitterBtn from '../assets/twitter-btn.png';
import shareBtn from '../assets/share-btn.png';

function shareButtons({ shareableUrl, shareFn, showShareTooltip }) {
    return (
        <div className="share-buttons">
            <a
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${shareableUrl}src=sdkpreparse`}
                className="share-btn-container"
                onClick={() => {
                    ReactGA.event({
                        category: 'share',
                        action: 'facebook'
                    });
                }}
            >
                <img className="share-btn" src={facebookBtn}></img>
            </a>
            <a
                className="share-btn-container"
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=${shareableUrl}`}
                data-size="large"
                onClick={() => {
                    ReactGA.event({
                        category: 'share',
                        action: 'twitter'
                    });
                }}
            >
                <img className="share-btn" src={twitterBtn}></img>
            </a>
            <div className="share-btn-container" onClick={shareFn}>
                {showShareTooltip && <div className="tooltip-share">Link copiado.</div>}
                <img className="share-btn" src={shareBtn}></img>
            </div>
        </div>
    );
}

export default shareButtons;

import React from 'react';

function companiesShare({ message, url, logoUrl }) {
    return (
        <div className="company-share-message-container">
            <div className="company-share-message">
                <div className="company-share-quote">
                    <span className="aspas">"</span>
                    {message}
                    <span className="aspas">"</span>
                </div>
                <a href={url} target="_blank">
                    <div className="company-share-logo">
                        <img src={logoUrl}></img>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default companiesShare;

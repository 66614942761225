import React from 'react';
import cloud1 from '../assets/cloud1.png';

import number365_pt from '../assets/numbers/365_dias_pt.svg';
import horas24_pt from '../assets/numbers/24_horas_pt.svg';
import activities_pt from '../assets/numbers/172_actividades_pt.svg';
import saude_pt from '../assets/numbers/1705_saude_pt.svg';
import euros20_pt from '../assets/numbers/20_euros_pt.svg';

import number365_es from '../assets/numbers/365_dias_es.svg';
import horas24_es from '../assets/numbers/24_horas_es.svg';
import activities_es from '../assets/numbers/172_actividades_es.svg';
import saude_es from '../assets/numbers/1705_saude_es.svg';
import euros20_es from '../assets/numbers/20_euros_es.svg';

import number365_en from '../assets/numbers/365_dias_en.svg';
import horas24_en from '../assets/numbers/24_horas_en.svg';
import activities_en from '../assets/numbers/172_actividades_en.svg';
import saude_en from '../assets/numbers/1705_saude_en.svg';
import euros20_en from '../assets/numbers/20_euros_en.svg';

import numberBottomCloud from '../assets/base_2_linhas.svg';
import { withTranslation } from 'react-i18next';

const numberImages = {
    pt: {
        number365: number365_pt,
        horas24: horas24_pt,
        activities: activities_pt,
        saude: saude_pt,
        euros20: euros20_pt
    },
    en: {
        number365: number365_en,
        horas24: horas24_en,
        activities: activities_en,
        saude: saude_en,
        euros20: euros20_en
    },
    es: {
        number365: number365_es,
        horas24: horas24_es,
        activities: activities_es,
        saude: saude_es,
        euros20: euros20_es
    }
};

function stats({ t }) {
    const lang = localStorage.getItem('i18nextLng').substring(0, 2);
    const images = numberImages[lang] || numberImages.en;
    return (
        <div className="big-area  big-area-color">
            <div className="content-size ">
                <div className="cloud-1">
                    <img src={cloud1}></img>
                </div>
                <h3 className="center-title numbers-title">{t('A Casa Damião em números')}</h3>
                <div className="stats-list">
                    <div className="stats-image">
                        <img className="number365" src={images.number365} />
                    </div>
                    <div className="stats-image">
                        <img className="horas24" src={images.horas24} />
                    </div>
                    <div className="stats-image">
                        <img className="saude" src={images.saude} />
                    </div>

                    <div className="stats-image">
                        <img className="activities" src={images.activities} />
                    </div>
                    <div className="stats-image">
                        <img className="horas24" src={images.euros20} />
                    </div>

                    <div className="stats-image-cloud bottom-cloud">
                        <img src={numberBottomCloud} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(stats);

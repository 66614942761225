import React from 'react';
import { Range, getTrackBackground } from 'react-range';

function namesSlider({ names = [], onChange, value }) {
    return (
        <Range
            step={1}
            min={0}
            max={names.length ? names.length - 1 : 1}
            values={[value]}
            onChange={newValue => {
                onChange(newValue);
            }}
            renderTrack={({ props, children }) => (
                <div
                    className="render-track"
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    style={{
                        ...props.style,
                        display: 'flex',
                        width: '100%'
                    }}
                >
                    <div
                        ref={props.ref}
                        className="render-track-line"
                        style={{
                            background: getTrackBackground({
                                values: [value],
                                colors: ['#ff7373', '#e2e2e2'],
                                min: 0,
                                max: names.length ? names.length - 1 : 1
                            }),
                            alignSelf: 'center'
                        }}
                    >
                        {children}
                    </div>
                </div>
            )}
            renderThumb={({ props }) => (
                <div
                    className="render-thumb"
                    {...props}
                    style={{
                        ...props.style
                    }}
                >
                    <div className="small-heart-range"></div>
                </div>
            )}
        ></Range>
    );
}

export default namesSlider;

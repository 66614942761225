import React from 'react';
import constants from '../constants';
import CompaniesMessages from '../components/list-companies-messages';
import CreateMessage from '../components/create-message';
import ListDonations from '../components/list-donations';
import DonationForm from '../components/donation-form';
import { getDonations } from '../http-helper';
import { withRouter } from 'react-router-dom';

const PAGE_TABS = {
    LIST_COMPANIES: 'LIST_COMPANIES',
    CREATE_COMPANY: 'CREATE_COMPANY',
    LIST_DONATIONS: 'LIST_DONATIONS',
    EDIT_COMPANY: 'EDIT_COMPANY',
    CREATE_DONATION: 'CREATE_DONATION',
    SPONSORS: 'SPONSORS'
};

const formatNumber = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
});

const formatAmount = function(amount) {
    return formatNumber.format(Math.round(amount / 100));
};

class adminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: window.localStorage.getItem('password') || '',
            validPasswordClient: (window.localStorage.getItem('password') || '').substring(3, 7) == 'inda',
            messages: [],
            tryDeleteId: undefined,
            tab: PAGE_TABS.LIST_DONATIONS,
            newCompanyName: '',
            newCompanyNameId: '',
            newCompanyUrl: '',
            newCompanyLogoUrl: '',
            newCompanyMessage: '',
            editingCompanyId: '',
            error: '',
            loading: false,
            stats: { amounts: {} },
            donations: [],
            formDonationAmount: '',
            formDonationEmail: '',
            formDonationDonor: '',
            formDonationNotes: '',
            sponsors: []
        };

        this.tryDeleteCompany = this.tryDeleteCompany.bind(this);
        this.deleteCompany = this.deleteCompany.bind(this);
        this.closeTryDelete = this.closeTryDelete.bind(this);
        this.createNewCompany = this.createNewCompany.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.editCompany = this.editCompany.bind(this);
        this.submitDonation = this.submitDonation.bind(this);
        this.refreshPageData = this.refreshPageData.bind(this);
        this.deleteDonation = this.deleteDonation.bind(this);
        this.deleteData = this.deleteData.bind(this);
        this.saveSponsors = this.saveSponsors.bind(this);
        this.changePageTab = this.changePageTab.bind(this);
    }

    changePageTab(tab) {
        this.setState({ tab });
    }

    saveSponsors(ev) {
        ev.preventDefault();
        this.setState({ error: '', loading: true });

        const data = {
            sponsors: this.state.sponsors,
            password: this.state.password
        };

        let apiUrl = `${constants.apiEndpoint}/save-sponsors.php`;

        return this.submitForm(apiUrl, data, PAGE_TABS.SPONSORS);
    }

    tryDeleteCompany(id) {
        this.setState({ tryDeleteId: id });
    }

    deleteDonation(id) {
        const url = `${constants.apiEndpoint}/delete-donation.php?donationId=${id}&password=${this.state.password}`;
        return this.deleteData(url);
    }

    deleteCompany(id) {
        const url = `${constants.apiEndpoint}/delete-company-message.php?messageId=${this.state.tryDeleteId}&password=${this.state.password}`;
        return this.deleteData(url);
    }

    deleteData(url) {
        this.setState({ loading: true, error: '' });
        fetch(url)
            .then(response => response.json())
            .then(response => {
                this.setState({ error: response.error });

                if (response.status == 'success') {
                    return this.refreshPageData();
                }
            })
            .catch(() => {
                this.setState({ error: 'Erro, algo correu mal. Valide os dados.' });
            })
            .then(() => {
                this.setState({ loading: false });
            });
    }

    editCompany(id) {
        const message = this.state.messages.find(message => message.id === id);

        this.setState({
            newCompanyName: message.name,
            newCompanyNameId: message.nameId,
            newCompanyUrl: message.url,
            newCompanyLogoUrl: message.logoUrl,
            newCompanyMessage: message.message,
            editingCompanyId: message.id,
            tab: PAGE_TABS.EDIT_COMPANY
        });
    }

    clearForm() {
        this.setState({
            newCompanyName: '',
            newCompanyNameId: '',
            newCompanyUrl: '',
            newCompanyLogoUrl: '',
            newCompanyMessage: '',
            editingCompanyId: '',
            tryDeleteId: undefined,
            formDonationAmount: '',
            formDonationEmail: '',
            formDonationDonor: '',
            formDonationNotes: ''
        });
    }

    closeTryDelete() {
        this.setState({ tryDeleteId: undefined });
    }

    createNewCompany() {
        this.clearForm();
        this.changePageTab(PAGE_TABS.CREATE_COMPANY);
    }

    refreshPageData() {
        this.clearForm();
        return getDonations().then(response => {
            this.setState({
                messages: response.messages,
                stats: response.donationStats,
                donations: response.donations,
                sponsors: response.sponsors
            });
        });
    }

    submitDonation(ev) {
        ev.preventDefault();
        this.setState({ error: '', loading: true });

        const data = {
            amount: this.state.formDonationAmount,
            email: this.state.formDonationEmail,
            notes: this.state.formDonationNotes,
            donor: this.state.formDonationDonor,
            password: this.state.password
        };

        const apiUrl = `${constants.apiEndpoint}/save-donation.php`;

        return this.submitForm(apiUrl, data, PAGE_TABS.LIST_DONATIONS);
    }

    formSubmit(ev) {
        ev.preventDefault();

        const data = {
            companyName: this.state.newCompanyName,
            companyNameId: this.state.newCompanyNameId,
            companyUrl: this.state.newCompanyUrl,
            companyLogoUrl: this.state.newCompanyLogoUrl,
            companyMessage: this.state.newCompanyMessage,
            companyId: this.state.editingCompanyId,
            password: this.state.password
        };

        let apiUrl = `${constants.apiEndpoint}/save-company.php`;
        if (this.state.tab === PAGE_TABS.EDIT_COMPANY) {
            apiUrl = `${constants.apiEndpoint}/edit-company-message.php`;
        }

        return this.submitForm(apiUrl, data, PAGE_TABS.LIST_COMPANIES);
    }

    submitForm(url, data, goToTab) {
        this.setState({ error: '', loading: true });

        return fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors',
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    this.setState({ error: response.error });
                    return;
                }

                if (response.status == 'success') {
                    this.changePageTab(goToTab);
                    return this.refreshPageData();
                }
            })
            .catch(() => {
                this.setState({ error: 'Erro, algo correu mal. Valide os dados.' });
            })
            .then(() => {
                this.setState({ loading: false });
            });
    }

    componentDidMount() {
        if (!this.state.validPasswordClient) {
            return;
        }

        return this.refreshPageData();
    }

    render() {
        return (
            <div className="admin-container">
                <div>
                    Password:
                    <input
                        disabled={this.state.loading}
                        type="password"
                        value={this.state.password}
                        onChange={ev => {
                            this.setState({ password: ev.target.value });
                            window.localStorage.setItem('password', ev.target.value);
                        }}
                    ></input>
                </div>
                <div className="admin-error">{this.state.error}</div>
                <h2>Stats:</h2>
                <div className="admin-stats">
                    <div>
                        <h3>Nomes no coração:</h3>
                        <div>Nomes pagos: {this.state.stats.payedNames}</div>
                        <div>Nomes não pagos: {this.state.stats.noPaymentNames}</div>
                    </div>
                    <div>
                        <h3>Pagamentos:</h3>
                        <div>Total de doações: {this.state.stats.numberOfDonors}</div>
                        <div>Valor: {this.state.stats.totalAmount}</div>
                        <hr></hr>
                        {Object.keys(this.state.stats.amounts).map(amount => {
                            return (
                                <div key={amount}>
                                    {formatAmount(amount)} - {this.state.stats.amounts[amount]}
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <h3>Métodos de pagamento:</h3>
                        <div>MB Way: {this.state.stats.numberMBwayPayments}</div>
                        <div>Multibanco: {this.state.stats.numberMBPayments}</div>
                        <div>Cartão: {this.state.stats.numberCardPayments}</div>
                        <div>Manual: {this.state.stats.numberManualPayments}</div>
                    </div>
                </div>
                <button
                    className={`tab-button tab-${this.state.tab === PAGE_TABS.LIST_DONATIONS}`}
                    onClick={() => this.changePageTab(PAGE_TABS.LIST_DONATIONS)}
                >
                    Lista de doaçoes
                </button>
                <button
                    className={`tab-button tab-${this.state.tab === PAGE_TABS.LIST_COMPANIES}`}
                    onClick={() => this.changePageTab(PAGE_TABS.LIST_COMPANIES)}
                >
                    Lista de empresas
                </button>
                <button
                    className={`tab-button tab-${this.state.tab === PAGE_TABS.SPONSORS}`}
                    onClick={() => this.changePageTab(PAGE_TABS.SPONSORS)}
                >
                    Patrocinadores
                </button>

                {this.state.tab === PAGE_TABS.LIST_COMPANIES && (
                    <div>
                        <h2>Lista de empresas</h2>
                        <button
                            className={`tab-button tab-${this.state.tab === PAGE_TABS.CREATE_COMPANY}`}
                            onClick={this.createNewCompany}
                        >
                            Criar Nova Empresa
                        </button>
                        <CompaniesMessages
                            messages={this.state.messages}
                            tryDeleteId={this.state.tryDeleteId}
                            tryDeleteCompany={this.tryDeleteCompany}
                            closeTryDelete={this.closeTryDelete}
                            deleteCompany={this.deleteCompany}
                            editCompany={this.editCompany}
                        ></CompaniesMessages>
                    </div>
                )}

                {this.state.tab === PAGE_TABS.CREATE_COMPANY && (
                    <div>
                        <h2>Nova empresa com mensagem</h2>
                        <CreateMessage
                            setState={arg => this.setState(arg)}
                            formSubmit={this.formSubmit}
                            loading={this.state.loading}
                            newCompanyName={this.state.newCompanyName}
                            newCompanyNameId={this.state.newCompanyNameId}
                            newCompanyUrl={this.state.newCompanyUrl}
                            newCompanyMessage={this.state.newCompanyMessage}
                            newCompanyLogoUrl={this.state.newCompanyLogoUrl}
                            error={this.state.error}
                        ></CreateMessage>
                    </div>
                )}

                {this.state.tab === PAGE_TABS.EDIT_COMPANY && (
                    <div>
                        <h2>Editar mensagem da empresa</h2>
                        <CreateMessage
                            setState={arg => this.setState(arg)}
                            formSubmit={this.formSubmit}
                            loading={this.state.loading}
                            newCompanyName={this.state.newCompanyName}
                            newCompanyNameId={this.state.newCompanyNameId}
                            newCompanyUrl={this.state.newCompanyUrl}
                            newCompanyMessage={this.state.newCompanyMessage}
                            newCompanyLogoUrl={this.state.newCompanyLogoUrl}
                            error={this.state.error}
                        ></CreateMessage>
                    </div>
                )}

                {this.state.tab === PAGE_TABS.CREATE_DONATION && (
                    <div>
                        <h2>Adicionar doação</h2>
                        <DonationForm
                            setState={state => this.setState(state)}
                            formDonationAmount={this.state.formDonationAmount}
                            formDonationNotes={this.state.formDonationNotes}
                            formDonationEmail={this.state.formDonationEmail}
                            formDonationDonor={this.state.formDonationDonor}
                            formSubmit={this.submitDonation}
                            error={this.state.error}
                        ></DonationForm>
                    </div>
                )}

                {this.state.tab === PAGE_TABS.LIST_DONATIONS && (
                    <div>
                        <h2>Lista de Doações</h2>
                        <button
                            className={`tab-button`}
                            onClick={() => {
                                this.clearForm();
                                this.changePageTab(PAGE_TABS.CREATE_DONATION);
                            }}
                        >
                            Adicionar Doação
                        </button>
                        <ListDonations
                            deleteDonation={this.deleteDonation}
                            donations={this.state.donations}
                        ></ListDonations>
                    </div>
                )}

                {this.state.tab === PAGE_TABS.SPONSORS && (
                    <div>
                        <h2>Patrocinadores</h2>
                        <button
                            onClick={() => {
                                let sponsors = this.state.sponsors;
                                sponsors.push({ name: '', url: '', logoUrl: '', displayOrder: '' });
                                this.setState({ sponsors });
                            }}
                        >
                            Adicionar patrocinador
                        </button>
                        <form className="admin-form-container">
                            {this.state.sponsors.map((sponsor, i) => {
                                return (
                                    <div key={i} className="sponsor-item">
                                        <div>
                                            <div> Nome:</div>
                                            <input
                                                value={sponsor.name}
                                                onChange={ev => {
                                                    const sponsors = this.state.sponsors;
                                                    sponsors[i].name = ev.target.value;
                                                    this.setState({ sponsors });
                                                }}
                                                required
                                                disabled={this.state.loading}
                                            ></input>
                                        </div>
                                        <div>
                                            <div>Posição:</div>
                                            <input
                                                onChange={ev => {
                                                    const sponsors = this.state.sponsors;
                                                    sponsors[i].displayOrder = ev.target.value;
                                                    this.setState({ sponsors });
                                                }}
                                                required
                                                type="number"
                                                value={sponsor.displayOrder}
                                                disabled={this.state.loading}
                                            ></input>
                                        </div>
                                        <div>
                                            <div>Site: </div>
                                            <div>Começar por: https://</div>
                                            <input
                                                pattern="https://.*"
                                                value={sponsor.url}
                                                onChange={ev => {
                                                    const sponsors = this.state.sponsors;
                                                    sponsors[i].url = ev.target.value;
                                                    this.setState({ sponsors });
                                                }}
                                                required
                                                disabled={this.state.loading}
                                            ></input>
                                        </div>
                                        <div>
                                            <div>Logo url:</div>
                                            <div>Começar por: https://</div>

                                            <input
                                                pattern="https://.*"
                                                value={sponsor.logoUrl}
                                                onChange={ev => {
                                                    const sponsors = this.state.sponsors;
                                                    sponsors[i].logoUrl = ev.target.value;
                                                    this.setState({ sponsors });
                                                }}
                                                required
                                                disabled={this.state.loading}
                                            ></input>
                                        </div>
                                        <div className="sponsor-image-preview">
                                            <a href={sponsor.url} target="_blank">
                                                <img src={sponsor.logoUrl}></img>
                                            </a>
                                        </div>
                                        <button
                                            onClick={() => {
                                                let sponsors = this.state.sponsors;
                                                sponsors.push({ name: '', url: '', logoUrl: '', displayOrder: '' });
                                                this.setState({ sponsors });
                                            }}
                                        >
                                            Apagar {sponsor.name}
                                        </button>
                                    </div>
                                );
                            })}

                            <div className="admin-error">{this.state.error}</div>
                            <button onClick={this.saveSponsors} className="save-bnt-admin">
                                Guardar Patrocinadores
                            </button>
                        </form>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(adminPage);

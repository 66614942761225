import React from 'react';

export default ({ donations, deleteDonation }) => (
    <table className="admin-table">
        <tbody>
            {donations.map((donation, i) => {
                return (
                    <tr key={donation.id}>
                        <td>
                            {donation.donor}
                            {donation.anonym ? <span className="donor-anonym">Anónimo</span> : ''}
                        </td>
                        <td>
                            <div className="amount-table">{donation.amount}</div>
                        </td>
                        <td>
                            <div className={`donor-visible-${donation.payed}`}>
                                {donation.payed ? 'Pagou' : 'Não pagou'}
                            </div>
                            <div>{donation.paymentMethod}</div>
                            <div>{donation.email}</div>
                            <div>{donation.notes}</div>
                            <div>{donation.updatedAt}</div>
                        </td>
                        <td>
                            <div
                                className="admin-delete-ontable"
                                onClick={() => {
                                    const toDelete = window.confirm(`Apagar ${donation.amount}?`);
                                    if (toDelete) {
                                        deleteDonation(donation.id);
                                    }
                                }}
                            >
                                Apagar
                            </div>
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </table>
);

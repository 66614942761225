import React from 'react';

function footer() {
    return (
        <div className="footer">
            Casa Damião {new Date().getFullYear()} - <a href="https://casadamiao.pt">casadamiao.pt</a>
            <div className="languages">
                <a href="/?lng=pt" className="language-selector">
                    Português
                </a>
                <a href="/?lng=es" className="language-selector">
                    Español
                </a>
                <a href="/?lng=en" className="language-selector">
                    English
                </a>
            </div>
        </div>
    );
}

export default footer;
